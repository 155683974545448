<template>
  <div class="content w-1920">
    <Header />
    <Search />
    <div class="breadcrumb w-1200">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>订单中心</el-breadcrumb-item>
        <el-breadcrumb-item>订单:{{ list.orderNo }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="status w-1200">
      <div class="jieshao">
        <div class="jie_L">
          <Uimage :src="list.orderSkuVoList[0].url" alt="" />
        </div>
        <div class="jie_R">
          <div>送货方式：标物快递</div>
          <div>承运人：标物快递<span>快递咨询</span></div>
          <div>货运单号：{{ list.expressNum }}</div>
        </div>
      </div>
      <div class="status_R">
        <div class="shang">
          订单已经完成，感谢您在标物商城购物，欢迎您对本次交易及所购商品进行评价。
        </div>
        <div class="zhong">
          <div class="dingdan">
            <img
              v-if="list.orderStatus == 0"
              src="../../../assets/order/dingdan1.png"
              alt=""
            />
            <img v-else src="../../../assets/order/dingdan.png" alt="" />
            <div class="zt">提交订单</div>
            <div class="time">{{ list.orderTime }}</div>
          </div>
          <div class="jiantou">
            <img src="../../../assets/order/jiantou.png" alt="" />
          </div>
          <div class="fukuan">
            <img
              v-if="list.orderStatus == 1"
              src="../../../assets/order/fukuan1.png"
              alt=""
            />
            <img v-else src="../../../assets/order/fukuan.png" alt="" />
            <div class="zt">付款成功</div>
            <div class="time">{{ list.payTime }}</div>
          </div>
          <div class="jiantou">
            <img src="../../../assets/order/jiantou.png" alt="" />
          </div>
          <div class="shangpin">
            <img
              v-if="list.orderStatus == 2"
              src="../../../assets/order/shangpin1.png"
              alt=""
            />
            <img v-else src="../../../assets/order/shangpin.png" alt="" />
            <div class="zt">商品出库</div>
            <div class="time">{{ list.deliveryTime }}</div>
          </div>
          <div class="jiantou">
            <img src="../../../assets/order/jiantou.png" alt="" />
          </div>
          <div class="shouhuo">
            <img
              v-if="list.orderStatus == 3"
              src="../../../assets/order/shouhuo1.png"
              alt=""
            />
            <img v-else src="../../../assets/order/shouhuo.png" alt="" />
            <div class="zt">确认收货</div>
            <div class="time">{{ list.receiveTime }}</div>
          </div>
          <div class="jiantou">
            <img src="../../../assets/order/jiantou.png" alt="" />
          </div>
          <div class="wancheng">
            <img
              v-if="list.orderStatus == 4"
              src="../../../assets/order/wancheng1.png"
              alt=""
            />
            <img v-else src="../../../assets/order/wancheng.png" alt="" />
            <div class="zt">完成</div>
            <div class="time">{{ list.finishTime }}</div>
          </div>
        </div>
        <div class="xia">
          <!--          <img src="../../../assets/order/jifen.png" alt="" />-->
          <!--          <span>积分</span>-->
          <!--          <span class="num">+{{ jifen }}</span>-->
        </div>
      </div>
    </div>
    <div class="shouhuo_detail w-1200">
      <div class="xinxi">
        <span class="spen">收货人信息</span>
        <div class="xinxi1">
          <div class="xinxi1_L">收货人：</div>
          <div class="xinxi1_R">{{ list.customerName }}</div>
        </div>
        <div class="xinxi1">
          <div class="xinxi1_L">地址：</div>
          <div class="xinxi1_R">
            {{ list.customerAddress }}
          </div>
        </div>
        <div class="xinxi1">
          <div class="xinxi1_L">手机号：</div>
          <div class="xinxi1_R">
            {{ list.customerPhone }}
          </div>
        </div>
        <div class="xinxi1">
          <div class="xinxi1_L">派送方式：</div>
          <div class="xinxi1_R">{{ list.deliveryMethod }}</div>
        </div>
        <div class="xinxi1">
          <div class="xinxi1_L">买家备注：</div>
          <div class="xinxi1_R">
            {{ list.buyerRemark }}
          </div>
        </div>
        
      </div>
      <!-- <div class="peisong">
        <span class="spen">配送信息</span>
        <div class="xinxi1">
          <div class="xinxi1_L">配送方式：</div>
          <div class="xinxi1_R">标物快递</div>
        </div>
        <div class="xinxi1">
          <div class="xinxi1_L">期望送货日期：</div>
          <div class="xinxi1_R">
            2020-7-14
          </div>
        </div>
        <div class="xinxi1">
          <div class="xinxi1_L">期望配送时间：</div>
          <div class="xinxi1_R">
            9:00-15:00
          </div>
        </div>
      </div> -->
      <div class="fukuan">
        <span class="spen">付款信息</span>
        <div class="xinxi1">
          <div class="xinxi1_L">付款状态：</div>
          <div class="xinxi1_R" v-if="list.orderStatus == 0">待付款</div>
          <div
            class="xinxi1_R"
            v-else-if="
              list.orderStatus == 5 ||
                list.orderStatus == 6 ||
                list.orderStatus == 7 ||
                list.orderStatus == 10 ||
                list.orderStatus == 20
            "
          >
            --
          </div>
          <div class="xinxi1_R" v-else>已付款</div>
        </div>
        <div class="xinxi1">
          <div class="xinxi1_L">付款方式：</div>
          <div class="xinxi1_R" v-if="list.orderSource == 4">积分兑换</div>
          <div
            class="xinxi1_R"
            v-else-if="
              list.orderStatus == 5 ||
                list.orderStatus == 6 ||
                list.orderStatus == 7 ||
                list.orderStatus == 10 ||
                list.orderStatus == 20
            "
          >
            --
          </div>
          <div class="xinxi1_R" v-else>在线支付</div>
        </div>
        <div class="xinxi1">
          <div class="xinxi1_L">付款时间：</div>
          <div
            class="xinxi1_R"
            v-if="
              list.orderStatus == 5 ||
                list.orderStatus == 6 ||
                list.orderStatus == 7 ||
                list.orderStatus == 10 ||
                list.orderStatus == 20
            "
          >
            --
          </div>
          <div class="xinxi1_R" v-else>
            {{ list.payTime }}
          </div>
        </div>
      </div>
    </div>

    <div class="fahuo_detail w-1200" v-if="msg!=null">
      <div class="xinxi">
        <span class="spen">充装状态</span>
        <el-table v-if="msg == '充装任务'" :data="FillingList" border width="1145px">
          <el-table-column prop="CertificateNo" label="标物编号" width="240px">
          </el-table-column>
          <el-table-column prop="ProductName" label="标物名称" width="280px">
          </el-table-column>
          <el-table-column prop="CylinderNumber" label="气瓶编号" width="200px">
          </el-table-column>
          <el-table-column prop="TaskCompletionTime" label="充装完成时间" width="240px">
          </el-table-column>
          <el-table-column prop="FillingStatus" label="充装状态" width="160px">
            <template slot-scope="scope">
              <span v-if="scope.row.Consignor !=null && scope.row.Consignor != ''">已发货</span>
              <span v-if="scope.row.Consignor ==null || scope.row.Consignor == ''">
                <span v-if="scope.row.FillingStatus == '1'">待计算</span>
                <span v-if="scope.row.FillingStatus == '2'">待指派</span>
                <span v-if="scope.row.FillingStatus == '3'">待充装</span>
                <span v-if="scope.row.FillingStatus == '4'">正在充装</span>
                <span v-if="scope.row.FillingStatus == '5'">已关闭</span>
                <span v-if="scope.row.FillingStatus == '6'"><span v-if="scope.row.nspectionStatus == null||scope.row.InspectionStatus==''">待质检</span><span v-if="scope.row.nspectionStatus == '6'">质检完成</span></span>
                <span v-if="scope.row.FillingStatus == '7'">人工中止</span>
                <span v-if="scope.row.FillingStatus == '8'">部分指派</span>
                <span v-if="scope.row.FillingStatus == '9'">部分充装</span>
                <span v-if="scope.row.FillingStatus == '10'">气瓶预处理</span>
                <span v-if="scope.row.FillingStatus == '11'">气瓶预处理中</span>
                <span v-if="scope.row.FillingStatus == '12'">气瓶预处理中</span>
                <span v-if="scope.row.FillingStatus == '13'">重新指派</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-if="msg == '未充装'" :data="FillingList2" border width="1145px" :empty-text="Remark">
          <el-table-column prop="CertificateNo" label="标物编号" width="240px">
          </el-table-column>
          <el-table-column prop="gasName" label="标物名称" width="280px">
          </el-table-column>
          <el-table-column prop="CylinderNumber" label="气瓶编号" width="200px">
          </el-table-column>
          <el-table-column prop="TaskCompletionTime" label="充装完成时间" width="240px">
          </el-table-column>
          <el-table-column prop="FillingStatus" label="充装状态" width="160px">
          </el-table-column>
        </el-table>
        <el-table v-if="msg == '未审核至ERP'" :data="FillingList2" border width="1145px" empty-text="未审核至ERP">
          <el-table-column prop="CertificateNo" label="标物编号" width="240px">
          </el-table-column>
          <el-table-column prop="gasName" label="标物名称" width="280px">
          </el-table-column>
          <el-table-column prop="CylinderNumber" label="气瓶编号" width="200px">
          </el-table-column>
          <el-table-column prop="TaskCompletionTime" label="充装完成时间" width="240px">
          </el-table-column>
          <el-table-column prop="FillingStatus" label="充装状态" width="160px">
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="fahuo_detail w-1200" v-if="cylinderList != null && cylinderList.length > 0">
      <div class="xinxi">
        <span class="spen">发货信息</span>
        <el-table :data="cylinderList" border width="1145px">
          <el-table-column prop="certificateNo" label="标物编号" width="280px">
          </el-table-column>
          <el-table-column prop="gasName" label="标物名称" width="280px">
          </el-table-column>
          <el-table-column prop="cylinderCode" label="气瓶编号" width="280px">
          </el-table-column>
          <el-table-column prop="createTime" label="发货时间" width="280px">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="biaoge w-1200">
      <el-table :data="tableData" border width="100%">
        <el-table-column label="商品图片" width="200px">
          <template slot-scope="scope">
            <Uimage :src="scope.row.url" style="height: 130px;width: 150px" />
          </template>
        </el-table-column>
        <el-table-column prop="spuName" label="商品属性">
          <template slot-scope="scope">
            <div style="color: #333;height: 20px;">{{ scope.row.spuName }}</div>
            <div
              class="zufen"
              v-for="(item, index) in scope.row.contentValue"
              :key="index"
            >
              <div class="xinghao">{{ item.attrName }}：</div>
              <div class="xinghao" v-html="item.attrValue"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="skuPrice" label="标物价（￥）" width="150px">
        </el-table-column>
        <el-table-column prop="skuCount" label="商品数量" width="100px">
        </el-table-column>
        <el-table-column prop="buyerNote" label="产品备注" width="100px">
        </el-table-column>
      </el-table>
      <div v-if="list.orderSource == 4">
        <div class="zonghe">
          <div class="zonghe_L">总积分:</div>
          <div class="zonghe_R">
            {{ list.useScore }}
          </div>
        </div>
        <div class="zonghe">
          <div class="zonghe_L red">实付积分:</div>
          <div class="zonghe_R red1">
            {{ list.useScore }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="zonghe">
          <div class="zonghe_L">商品原价:</div>
          <div class="zonghe_R">
            ￥{{ Number(list.orderTotalAmount - list.orderFreight).toFixed(2) }}
          </div>
        </div>
        <div class="zonghe">
          <div class="zonghe_L">运费:</div>
          <div class="zonghe_R">
            +￥{{ Number(list.orderFreight).toFixed(2) }}
          </div>
        </div>
        <div class="zonghe">
          <div class="zonghe_L">优惠额度:</div>
          <div class="zonghe_R">
            -￥{{ Number(list.sellerDiscount).toFixed(2) }}
          </div>
        </div>
        <div class="zonghe">
          <div class="zonghe_L">购物卡减免:</div>
          <div class="zonghe_R">
            -￥{{ Number(list.cardAmount).toFixed(2) }}
          </div>
        </div>
        <div class="zonghe">
          <div v-if="list.orderStatus == 0" class="zonghe_L red">应付款:</div>
          <div v-else class="zonghe_L red">实付款:</div>
          <div class="zonghe_R red1">
            ￥{{ Number(list.orderAmount).toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
import Header from '@/components/header.vue';
import Foot from '@/components/foot.vue';
import Search from '@/components/order/search.vue';
export default {
  components: {
    Header,
    Foot,
    Search,
  },
  data() {
    return {
      tableData: [],
      cylinderList:[],
      FillingList:[{
        certificateNo:'',
      }],
      Remark:'',
      FillingList2:null,
      msg:'',
      list: [],
      jifen: '0',
    };
  },
  created() {
    this.getdetail(this.$route.query.orderId);
    this.orderToErpDetail(this.$route.query.orderId);
  },
  methods: {
    orderToErpDetail(e){
      get('api/syncData/orderToErpDetail?orderId=' + e + '').then((res) => {
        this.msg = res.data.data.msg;
        this.FillingList = res.data.data.data;
        if(this.msg=='未充装'){
          if(this.FillingList.Remark =='1'){
            this.Remark = '订单制作完成';
          }else if(this.FillingList.Remark =='2'){
            this.Remark = '合同生成';
          } else if(this.FillingList.Remark =='3'){
            this.Remark = '合同转生产';
          }
        }
      });
    },
    getdetail(e) {
      get('api/orderbasic/getorderbyid?orderId=' + e + '').then((res) => {
        res.data.data.orderSkuVoList.forEach((req) => {
          if(req.contentValue != null){
            req.contentValue = eval('(' + req.contentValue + ')');
          }else{
            req.contentValue = eval('([])');
          }
          
          if (req.value != '0mol/mol' && req.value != '0ppm' && req.value != null) {
              if (req.value.indexOf('mol/mol') != -1) {
                req.contentValue.push({
                  attrName: '确认含量',
                  attrValue: req.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                req.contentValue.push({
                  attrName: '确认含量',
                  attrValue: req.value,
                });
              }
            }
          req.contentValue.forEach((k, index) => {
            if (k.attrName == '浓度范围') {
              req.contentValue.splice(index, 1);
            }
          });
          this.list = res.data.data;
          this.tableData = res.data.data.orderSkuVoList;
          this.cylinderList = res.data.data.cylinderList;
          req.skuPrice = Number(req.skuPrice).toFixed(2);
          this.jifen += req.useScore;
        });
        for (let q = 0; q < res.data.data.orderSkuVoList.length; q++) {
            for (let j = q + 1; j < res.data.data.orderSkuVoList.length; j++) {
              if(res.data.data.orderSkuVoList[q].remark =='1'&& res.data.data.orderSkuVoList[j].remark=='1'){
                if (res.data.data.orderSkuVoList[q].prodCatId == res.data.data.orderSkuVoList[j].prodCatId&&res.data.data.orderSkuVoList[q].spuName == res.data.data.orderSkuVoList[j].spuName&&res.data.data.orderSkuVoList[q].storeId == res.data.data.orderSkuVoList[j].storeId&&res.data.data.orderSkuVoList[q].batchNumber == res.data.data.orderSkuVoList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < res.data.data.orderSkuVoList[j].contentValue.length;
                    p++
                  ) {
                    if (
                      res.data.data.orderSkuVoList[j].contentValue[p].attrName == '多组分' ||
                      res.data.data.orderSkuVoList[j].contentValue[p].attrName == '气体组分' ||
                      res.data.data.orderSkuVoList[j].contentValue[p].attrName == '确认含量'
                    ) {
                      res.data.data.orderSkuVoList[q].contentValue.push(
                        res.data.data.orderSkuVoList[j].contentValue[p]
                      );
                    }
                  }
                }
              }
            }
          }
          for (let q = 0; q < res.data.data.orderSkuVoList.length; q++) {
            for (let j = q+1; j < res.data.data.orderSkuVoList.length; j++) {
              if(res.data.data.orderSkuVoList[q].remark =='1'&& res.data.data.orderSkuVoList[j].remark=='1'){
                if (res.data.data.orderSkuVoList[q].prodCatId == res.data.data.orderSkuVoList[j].prodCatId&&res.data.data.orderSkuVoList[q].spuName == res.data.data.orderSkuVoList[j].spuName&&res.data.data.orderSkuVoList[q].storeId == res.data.data.orderSkuVoList[j].storeId&&res.data.data.orderSkuVoList[q].batchNumber == res.data.data.orderSkuVoList[j].batchNumber) {
                  res.data.data.orderSkuVoList.splice(j,1);
              }
            }
            }
          }
          for (let q = 0; q < res.data.data.orderSkuVoList.length; q++) {
            for (let j = q+1; j < res.data.data.orderSkuVoList.length; j++) {
              if(res.data.data.orderSkuVoList[q].remark =='1'&& res.data.data.orderSkuVoList[j].remark=='1'){
                if (res.data.data.orderSkuVoList[q].prodCatId == res.data.data.orderSkuVoList[j].prodCatId&&res.data.data.orderSkuVoList[q].spuName == res.data.data.orderSkuVoList[j].spuName&&res.data.data.orderSkuVoList[q].storeId == res.data.data.orderSkuVoList[j].storeId&&res.data.data.orderSkuVoList[q].batchNumber == res.data.data.orderSkuVoList[j].batchNumber) {
                  res.data.data.orderSkuVoList.splice(j,1);
              }
            }
            }
          }
          for (let q = 0; q < res.data.data.orderSkuVoList.length; q++) {
            for (let j = q+1; j < res.data.data.orderSkuVoList.length; j++) {
              if(res.data.data.orderSkuVoList[q].remark =='1'&& res.data.data.orderSkuVoList[j].remark=='1'){
                if (res.data.data.orderSkuVoList[q].prodCatId == res.data.data.orderSkuVoList[j].prodCatId&&res.data.data.orderSkuVoList[q].spuName == res.data.data.orderSkuVoList[j].spuName&&res.data.data.orderSkuVoList[q].storeId == res.data.data.orderSkuVoList[j].storeId&&res.data.data.orderSkuVoList[q].batchNumber == res.data.data.orderSkuVoList[j].batchNumber) {
                  res.data.data.orderSkuVoList.splice(j,1);
              }
            }
            }
          }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  background: #f5f5f5;
}
.breadcrumb {
  width: 1180px;
  margin: 0 auto;
  height: 43px;
  padding: 20px 0 10px 0;
}
.status {
  width: 1235px;
  height: 306px;
  border-top: 4px solid #ff9f48;
  margin: 0 auto;
  background: #fff;
  display: flex;

  .jieshao {
    width: 314px;
    height: 100%;
    padding: 30px 0 0 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #f4f4f4;
    .jie_L {
      width: 96px;
      height: 96px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .jie_R {
      height: 96px;
      font-size: 12px;
      transform: scale(1);
      color: #7a7a7a;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-top: 20px;
    }
  }
  .status_R {
    padding: 25px 0 28px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .shang {
      font-size: 14px;
      color: #989898;
    }
    .zhong {
      margin-left: -10px;
      display: flex;
      align-items: flex-start;
      .zt {
        margin-top: 20px;
      }
      .time {
        font-size: 12px;
        color: #828282;
        margin-top: 10px;
        padding-left: 10px;
        width: 140px;
      }
      .dingdan {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .jiantou {
        margin-left: -10px;
        display: flex;
        align-items: flex-end;
        height: 30px;
        img {
          width: 40px;
          margin: 0 20px;
        }
      }
      .fukuan {
        margin-left: -20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .shangpin {
        margin-left: -20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .shouhuo {
        margin-left: -20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 45px;
          height: 40px;
        }
      }
      .wancheng {
        margin-left: -20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 39px;
          height: 39px;
        }
      }
    }
    .xia {
      font-size: 12px;
      color: #767676;
      img {
        width: 23px;
        height: 15px;
        margin-right: 15px;
      }
      .num {
        color: #f60d06;
        margin-left: 10px;
      }
    }
  }
}
.wuliu {
  width: 1235px;
  height: 318px;
  background: #fff;
  margin: 0 auto;
  margin-top: 23px;
  display: flex;
  .jieshao {
    width: 314px;
    height: 100%;
    padding: 30px 0 0 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #f4f4f4;
    .jie_L {
      width: 96px;
      height: 96px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .jie_R {
      height: 96px;
      font-size: 12px;
      transform: scale(1);
      color: #7a7a7a;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-top: 20px;
    }
  }
}
.fahuo_detail{
  min-height: 230px;
  width: 100%;
  background: #fff;
  margin: 20px auto;
  padding-bottom: 10px;
  display: flex;
  .xinxi {
    width: 100%;
    height: 100%;
    padding-left: 38px;
    padding-right: 40px;
    font-size: 12px;
    color: #3f3f3f;
    .spen {
      height: 63px;
      line-height: 73px;
      font-weight: 600;
      font-size: 16px;
      color: #292929;
    }
  }
}
.shouhuo_detail {
  height: 230px;
  width: 100%;
  background: #fff;
  margin: 20px auto;
  display: flex;
  .xinxi {
    width: 45%;
    height: 100%;
    border-right: 1px solid #f3f3f3;
    padding-left: 38px;
    font-size: 12px;
    color: #3f3f3f;
    .spen {
      height: 63px;
      line-height: 73px;
      font-weight: 600;
      font-size: 16px;
      color: #292929;
    }
    .xinxi1 {
      display: flex;
      margin-top: 10px;
      .xinxi1_L {
        width: 100px;
      }
      .xinxi1_R {
        width: 300px;
      }
    }
  }
  // .peisong {
  //   border-right: 1px solid #f3f3f3;
  //   width: 400px;
  //   height: 100%;
  //   padding-left: 28px;
  //   font-size: 12px;
  //   color: #3f3f3f;
  //   .spen {
  //     height: 63px;
  //     line-height: 73px;
  //     font-weight: 600;
  //     font-size: 16px;
  //     color: #292929;
  //   }
  //   .xinxi1 {
  //     display: flex;
  //     margin-top: 10px;
  //     .xinxi1_L {
  //       width: 150px;
  //     }
  //     .xinxi1_R {
  //       width: 300px;
  //     }
  //   }
  // }
  .fukuan {
    padding-left: 130px;
    font-size: 12px;
    color: #3f3f3f;
    .spen {
      height: 63px;
      line-height: 73px;
      font-weight: 600;
      font-size: 16px;
      color: #292929;
    }
    .xinxi1 {
      display: flex;
      margin-top: 10px;
      .xinxi1_L {
        width: 100px;
      }
    }
  }
}
.biaoge {
  width: 1182px;
  background: #fff;
  margin: 20px auto;
  padding: 28px 27px;
  .zonghe {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #333;
    margin-top: 15px;
    .zonghe_L {
      width: 1060px;
      display: flex;
      justify-content: flex-end;
    }
    .red {
      color: #e33638;
    }
    .red1 {
      color: #e33638;
      font-size: 14px;
    }
  }
  .zufen {
    margin-left: 5px;
    font-size: 12px;
    display: flex;
  }
  .xinghao {
    color: #999;
  }
}
</style>
